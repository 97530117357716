<template>
  <div>
    <LayoutSwipeList class="mb-10">
      <SwiperSlide v-for="(item, index) in items" :key="index">
        <MagazineTeaser :magazine="item" :index="index" type="slider" />
      </SwiperSlide>
    </LayoutSwipeList>
  </div>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'
const props = withDefaults(defineProps<{ count?: number }>(), { count: 5 })

const nuxtApp = useNuxtApp()

const { data: items } = await useAsyncData(async () => {
  return await nuxtApp.$api.content.magazine(props.count)
})
</script>
